import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { RoutePath } from '../../routes';

const LabeledTextField = ({ record, source, url, ...rest }) => {
  const value = source.split('.').reduce((acc, key) => acc?.[key], record);

  if (!value) return null;

  return (
    <Typography
      component="span"
      variant="body2"
      addLabel
      {...rest}
    >
      <Link href={url} target="_blank" rel="noopener noreferrer">
        {value}
      </Link>
    </Typography>
  );
};

LabeledTextField.displayName = 'LabeledTextField';
LabeledTextField.defaultProps = {
  addLabel: true,
};
  


export default LabeledTextField;
