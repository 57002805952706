import React, { useEffect, useState } from 'react';
import {
  SimpleShowLayout, TextField, BooleanField, useTranslate, useShowController,
} from 'react-admin';

import Link from '@material-ui/core/Link';

import FieldLink from '../../components/FieldLink';
import FormatField from '../../components/fields/FormatField';
import FormatChipField from '../../components/fields/FormatChipField';
import FormatValueField from '../../components/fields/FormatValueField';
import GridShowMediator from '../../components/GridShowMediator';
import ImageField from '../../components/fields/ImageField';
import ListField from '../../components/fields/ListField';
import RestrictedShow from '../../components/admin/RestrictedShow';
import UserTitle from './UserTitle';
import Changelog from '../../components/Changelog';

import { Role } from '../../constants';
import { RoutePath, getUrl } from '../../routes';
import { getRole } from '../../storages/auth';
import { formatDisplayDate, formatPersonName, formatSex } from '../../utils/formatters';
import useTelemedicService from '../../hooks/useTelemedicService';

const SERVER_NAME_TO_LABEL = {
  'kis_art_id': 'telemedic.common.kisArtId',
  'first_name': 'telemedic.users.form.firstName',
  'middle_name': 'telemedic.users.form.middleName',
  'last_name': 'telemedic.users.form.lastName',
  'sex': 'telemedic.users.show.sex',
  'is_allowed_to_auth_with_esia': 'telemedic.users.show.isAllowedToAuthWithEsia',
  'esia_id': 'telemedic.users.show.esiaId',
  'organization.name': 'telemedic.users.show.organization',
  'personnel_number': 'telemedic.users.show.personnelNumber',
  'card_id': 'telemedic.users.show.cardId',
  'position': 'telemedic.users.show.position',
  'date_hired': 'telemedic.users.show.dateHired',
  'phone': 'telemedic.users.show.phone',
  'driving_license_serial': 'telemedic.users.show.drivingLicenseSerial',
  'driving_license_number': 'telemedic.users.show.drivingLicenseNumber',
  'driving_license_date': 'telemedic.users.show.drivingLicenseDate',
  'passport': 'telemedic.users.show.passport',
  'passport_date': 'telemedic.users.show.passportDate',
  'passport_by': 'telemedic.users.show.passportBy',
  'snils': 'telemedic.users.show.snils',
  'license_card_number': 'telemedic.users.show.licenseCardNumber',
  'license_card_date_start': 'telemedic.users.show.licenseCardDateStart',
  'license_card_date_end': 'telemedic.users.show.licenseCardDateEnd',
  'birthday': 'telemedic.users.show.birthday',
  'min_arterial_pressure': 'telemedic.users.form.minArterialPressure',
  'max_arterial_pressure': 'telemedic.users.form.maxArterialPressure',
  'min_venous_pressure': 'telemedic.users.form.minVenousPressure',
  'max_venous_pressure': 'telemedic.users.form.maxVenousPressure',
  'min_pulse': 'telemedic.users.form.minPulse',
  'max_pulse': 'telemedic.users.form.maxPulse',
  'max_temperature': 'telemedic.users.show.maxTemperature',
  'chronic_diseases': 'telemedic.users.show.chronicDiseases',
  'diagnosis': 'telemedic.users.show.diagnosis',
  'face_recognition_id': 'telemedic.users.show.faceRecognitionId',
  'test_question_categories': 'telemedic.users.show.testQuestionCategories',
  'is_fired': 'telemedic.users.show.isFired',
  'is_not_included_in_work_rest_report': 'telemedic.users.show.isNotIncludedInWorkRestReport',
  'photo': 'telemedic.users.show.photo',
  'med_certificate': 'telemedic.users.show.medCertificate'
};

const getTestQuestionCategoryUrl = (record) => (
  record ? getUrl(RoutePath.TEST_QUESTION_CATEGORIES_SHOW, { id: record.id }) : ''
);

const formatTestQuestionCategory = (record) => (
  record ? `${record.id} - ${record.category}` : ''
);

const UserShow = (props) => {
  const translate = useTranslate();
  const formatRange = (minValue, maxValue) => {
    if (minValue) {
      if (maxValue) {
        return `${minValue} … ${maxValue}`;
      }
      return `> ${minValue}`;
    }
    if (maxValue) {
      return `< ${maxValue}`;
    }
    return '';
  };
  const arterialPressureFormatter = ({ min_arterial_pressure, max_arterial_pressure }) => {
    return formatRange(min_arterial_pressure, max_arterial_pressure);
  };
  const venousPressureFormatter = ({ min_venous_pressure, max_venous_pressure }) => {
    return formatRange(min_venous_pressure, max_venous_pressure);
  };
  const pulseFormatter = ({ min_pulse, max_pulse }) => {
    return formatRange(min_pulse, max_pulse);
  };
  const role = getRole();
  const showIndividualParameters = (role !== Role.DISPATCHER);
  const showMedCertificate = (role !== Role.DISPATCHER);
  const showDiagnosis = (role === Role.ADMIN || role === Role.MEDIC);

  const service = useTelemedicService();
  const [changelog, setChangelog] = useState([]);
  const { record } = useShowController(props);
  useEffect(() => {
    if (!record) {
      return;
    }
    service.getUserChangelog(record.id).then((response) => {
      if (response.status === 200) {
        setChangelog(response.json.changes);
      }
    });
  }, [record, service]);

  return (
    <RestrictedShow title={<UserTitle />} {...props}>
      <GridShowMediator container>
        <GridShowMediator xs={12} md={6} item>
          <SimpleShowLayout>
            <TextField label="telemedic.common.kisArtId" source="kis_art_id" />
            <FormatField label="telemedic.users.show.name" formatter={formatPersonName} />
            <FormatValueField
              label="telemedic.users.show.sex"
              source="sex"
              formatter={formatSex}
            />
            <BooleanField
              label="telemedic.users.show.isAllowedToAuthWithEsia"
              source="is_allowed_to_auth_with_esia"
            />
            <TextField
              label="telemedic.users.show.esiaId"
              source="esia_id"
            />
            <TextField label="telemedic.users.show.organization" source="organization.name" />
            <TextField label="telemedic.users.show.personnelNumber" source="personnel_number" />
            <TextField label="telemedic.users.show.cardId" source="card_id" />
            <TextField label="telemedic.users.show.position" source="position" />
            <FormatValueField
              label="telemedic.users.show.dateHired"
              source="date_hired"
              formatter={formatDisplayDate}
            />
            <TextField label="telemedic.users.show.phone" source="phone" />
            <TextField label="telemedic.users.show.drivingLicenseSerial" source="driving_license_serial" />
            <TextField label="telemedic.users.show.drivingLicenseNumber" source="driving_license_number" />
            <FormatValueField
              label="telemedic.users.show.drivingLicenseDate"
              source="driving_license_date"
              formatter={formatDisplayDate}
            />
            <TextField label="telemedic.users.show.passport" source="passport" />
            <FormatValueField
              label="telemedic.users.show.passportDate"
              source="passport_date"
              formatter={formatDisplayDate}
            />
            <TextField label="telemedic.users.show.passportBy" source="passport_by" />
            <TextField label="telemedic.users.show.snils" source="snils" />
            <TextField label="telemedic.users.show.licenseCardNumber" source="license_card_number" />
            <FormatValueField
              label="telemedic.users.show.licenseCardDateStart"
              source="license_card_date_start"
              formatter={formatDisplayDate}
            />
            <FormatValueField
              label="telemedic.users.show.licenseCardDateEnd"
              source="license_card_date_end"
              formatter={formatDisplayDate}
            />
            <FormatValueField
              label="telemedic.users.show.birthday"
              source="birthday"
              formatter={formatDisplayDate}
            />
            {
              showIndividualParameters
                && <FormatField label="telemedic.users.show.arterialPressure" formatter={arterialPressureFormatter} />
            }
            {
              showIndividualParameters
                && <FormatField label="telemedic.users.show.venousPressure" formatter={venousPressureFormatter} />
            }
            {
              showIndividualParameters
                && <FormatField label="telemedic.users.show.pulse" source="pulse" formatter={pulseFormatter} />
            }
            {
              showIndividualParameters
                && <TextField label="telemedic.users.show.maxTemperature" source="max_temperature" />
            }
            {
              showDiagnosis
                && <TextField label="telemedic.users.show.chronicDiseases" source="chronic_diseases" />
            }
            {
              showDiagnosis
                && <TextField label="telemedic.users.show.diagnosis" source="diagnosis" />
            }
            <TextField label="telemedic.users.show.faceRecognitionId" source="face_recognition_id" />
            <ListField
              label="telemedic.users.show.testQuestionCategories"
              source="test_question_categories"
            >
              <FieldLink getUrl={getTestQuestionCategoryUrl}>
                <FormatChipField formatter={formatTestQuestionCategory} clickable />
              </FieldLink>
            </ListField>
            <BooleanField
              label="telemedic.users.show.enabledEpl"
              source="enabled_epl"
            />
            <BooleanField
              label="telemedic.users.show.isFired"
              source="is_fired"
            />
            <BooleanField
              label="telemedic.users.show.isNotIncludedInWorkRestReport"
              source="is_not_included_in_work_rest_report"
            />
            <Link href={getUrl(RoutePath.USER_HEALTH_STATUS, { userId: props.id })}>
              <h5>{translate('telemedic.users.show.healthReport')}</h5>
            </Link>
            <Changelog
              changelog={changelog}
              fieldsLabels={SERVER_NAME_TO_LABEL}
            />
          </SimpleShowLayout>
        </GridShowMediator>
        <GridShowMediator xs={12} md={6} item>
          <ImageField
            label="telemedic.users.show.photo"
            source="photo"
          />
          {
            showMedCertificate && (
              <ImageField
                label="telemedic.users.show.medCertificate"
                source="med_certificate"
              />
            )
          }
        </GridShowMediator>
      </GridShowMediator>
    </RestrictedShow>
  );
};

export { UserShow };
