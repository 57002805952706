import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  MenuItemLink, useLogout, usePermissions, useTranslate,
} from 'react-admin';

import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AdjustIcon from '@material-ui/icons/Adjust';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AppsIcon from '@material-ui/icons/Apps';
import ArchiveIcon from '@material-ui/icons/Archive';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ComputerIcon from '@material-ui/icons/Computer';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ExitIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SmsIcon from '@material-ui/icons/Sms';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MapIcon from '@material-ui/icons/Map';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonIcon from '@material-ui/icons/Person';
import PrintIcon from '@material-ui/icons/Print';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SearchIcon from '@material-ui/icons/Search';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import SpeedIcon from '@material-ui/icons/Speed';
import WorkIcon from '@material-ui/icons/Work';
import { makeStyles } from '@material-ui/core/styles';

import SubMenu from './SubMenu';

import { Section, Action } from '../permissions';
import { RoutePath } from '../routes';
import { SERVER_MONITORING_URL, TERMINAL_MONITORING_URL } from '../constants';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: 'break-spaces',
  },
  menuItemActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    color: 'black',
  },
}));

const openServerMonitoringUrl = (e) => {
  e.preventDefault();
  window.open(SERVER_MONITORING_URL, '_blank');
};

const openTerminalMonitoringUrl = (e) => {
  e.preventDefault();
  window.open(TERMINAL_MONITORING_URL, '_blank');
};

const StyledMenuItemLink = (props) => {
  const classes = useStyles();
  return (
    <MenuItemLink
      {...props}
      className={classes.menuItem}
      activeClassName={classes.menuItemActive}
    />
  );
};

const Menu = ({ onMenuClick, dense }) => {
  const { permissions } = usePermissions();
  const [menuOpened, setMenuOpened] = useState({
    reports: false,
  });
  const logout = useLogout();
  const translate = useTranslate();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change
  const toggleMenu = (menu) => {
    setMenuOpened({ ...menuOpened, [menu]: !menuOpened[menu] });
  };
  if (!permissions) {
    return <div />;
  }
  return (
    <div>
      <SubMenu
        handleToggle={() => toggleMenu('main')}
        isOpen={menuOpened.main}
        sidebarIsOpen={open}
        text={translate('telemedic.menu.main')}
        icon={<AppsIcon />}
        dense={dense}
      >
        <StyledMenuItemLink
          to={RoutePath.DASHBOARD}
          primaryText={translate('telemedic.dashboard.label')}
          leftIcon={<HomeIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        {
          (permissions.check(Section.TERMINALS_MAP, Action.READ)) && (
          <StyledMenuItemLink
            to={RoutePath.TERMINALS_MAP}
            primaryText={translate('telemedic.terminalsMap.label')}
            leftIcon={<MapIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />)
        }
        <StyledMenuItemLink
          to={RoutePath.USEFUL_LINKS}
          primaryText={translate('telemedic.usefulLinks.label')}
          leftIcon={<InfoIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <StyledMenuItemLink
          to={RoutePath.FEEDBACK_FORM}
          primaryText={translate('telemedic.feedback.label')}
          leftIcon={<ContactSupportIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      {
        (permissions.check(Section.DEALER_BALANCE, Action.READ)
        || permissions.check(Section.DEALER_PAYMENTS, Action.READ)
        || permissions.check(Section.ORG_BALANCE, Action.READ)
        || permissions.check(Section.ORG_PAYMENTS, Action.READ)) && (
          <SubMenu
            handleToggle={() => toggleMenu('bills')}
            isOpen={menuOpened.bills}
            sidebarIsOpen={open}
            text={translate('telemedic.menu.bills')}
            icon={<AttachMoneyIcon />}
            dense={dense}
          >
            {
              permissions.check(Section.DEALER_BALANCE, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.DEALER_BALANCE}
                  primaryText={translate('telemedic.dealerBalance.label')}
                  leftIcon={<AccountBalanceWalletIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.DEALER_PAYMENTS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.DEALER_PAYMENTS}
                  primaryText={translate('telemedic.dealerPayments.label')}
                  leftIcon={<AttachMoneyIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.ORG_BALANCE, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.ORG_BALANCE}
                  primaryText={translate('telemedic.orgBalance.label')}
                  leftIcon={<AccountBalanceWalletIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.ORG_PAYMENTS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.ORG_PAYMENTS}
                  primaryText={translate('telemedic.orgPayments.label')}
                  leftIcon={<AttachMoneyIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
          </SubMenu>
        )
      }
      {
        (permissions.check(Section.CHECKUPS, Action.READ)
        || permissions.check(Section.SIGNED_CHECKUP_JOURNALS, Action.READ)
        || permissions.check(Section.PERIODIC_MAILINGS, Action.READ)) && (
        <SubMenu
          handleToggle={() => toggleMenu('checkups')}
          isOpen={menuOpened.checkups}
          sidebarIsOpen={open}
          text={translate('telemedic.menu.checkups')}
          icon={<CheckCircleIcon />}
          dense={dense}
        >
          {
            permissions.check(Section.CHECKUPS, Action.READ) && (
              <StyledMenuItemLink
                to={RoutePath.CHECKUPS_LIVE}
                primaryText={translate('telemedic.checkupsLive.label')}
                leftIcon={<CheckCircleIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )
          }
          {
            permissions.check(Section.CHECKUPS, Action.READ) && (
              <StyledMenuItemLink
                to={RoutePath.CHECKUPS}
                primaryText={translate('telemedic.checkups.label')}
                leftIcon={<AccessTimeIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )
          }
          {
            permissions.check(Section.CHECKUP_JOURNALS, Action.READ) && (
              <StyledMenuItemLink
                to={RoutePath.CHECKUP_JOURNALS}
                primaryText={translate('telemedic.checkupJournals.label')}
                leftIcon={<PrintIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )
          }
          {
            permissions.check(Section.SIGNED_CHECKUP_JOURNALS, Action.READ) && (
              <StyledMenuItemLink
                to={RoutePath.SIGNED_CHECKUP_JOURNALS}
                primaryText={translate('telemedic.signedCheckupJournals.label')}
                leftIcon={<MenuBookIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )
          }
          {
            permissions.check(Section.PERIODIC_MAILINGS, Action.READ) && (
              <StyledMenuItemLink
                to={RoutePath.PERIODIC_MAILINGS}
                primaryText={translate('telemedic.periodicMailings.label')}
                leftIcon={<AlternateEmailIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )
          }
        </SubMenu>
        )
      }
      {
        (permissions.check(Section.ORGANIZATIONS, Action.READ)
        || permissions.check(Section.CHECK_ORG_INN, Action.READ)
        || permissions.check(Section.DEALERS, Action.READ)
        || permissions.check(Section.DEALERS_RESPONSIBLE, Action.READ)
        || permissions.check(Section.MED_ORGANIZATIONS, Action.READ)
        || permissions.check(Section.USERS, Action.READ)
        || permissions.check(Section.WORKERS, Action.READ)) && (
          <SubMenu
            handleToggle={() => toggleMenu('structure')}
            isOpen={menuOpened.structure}
            sidebarIsOpen={open}
            text={translate('telemedic.menu.structure')}
            icon={<AccountTreeIcon />}
            dense={dense}
          >
            {
              permissions.check(Section.ORGANIZATIONS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.ORGANIZATIONS}
                  primaryText={translate('telemedic.organizations.label')}
                  leftIcon={<BusinessIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.CHECK_ORG_INN, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.CHECK_ORG_INN}
                  primaryText={translate('telemedic.checkOrgInn.label')}
                  leftIcon={<SearchIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.DEALERS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.DEALERS}
                  primaryText={translate('telemedic.dealers.label')}
                  leftIcon={<WorkIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.DEALERS_RESPONSIBLE, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.DEALERS_RESPONSIBLE}
                  primaryText={translate('telemedic.dealersResponsible.label')}
                  leftIcon={<PersonIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.MED_ORGANIZATIONS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.MED_ORGANIZATIONS}
                  primaryText={translate('telemedic.medOrganizations.label')}
                  leftIcon={<BusinessIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.USERS, Action.READ) && (
              <StyledMenuItemLink
                to={RoutePath.USERS}
                primaryText={translate('telemedic.users.label')}
                leftIcon={<PersonIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
              )
            }
            {
              permissions.check(Section.WORKERS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.WORKERS}
                  primaryText={translate('telemedic.workers.label')}
                  leftIcon={<PersonIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
          </SubMenu>
        )
      }
      {
        (permissions.check(Section.CHECKUPS, Action.IMPORT)
        || permissions.check(Section.ALCOHOL_SMS_REPORT, Action.READ)
        || permissions.check(Section.AIS_ERP_INTEGRATION, Action.READ)
        || permissions.check(Section.TERMINALS, Action.READ)
        || permissions.check(Section.USERS, Action.CREATE)
        || permissions.check(Section.VEHICLES, Action.CREATE)
        || permissions.check(Section.ACTIVE_USERS, Action.CREATE)
        || permissions.check(Section.DISK_SPACE, Action.CREATE)) && (
          <SubMenu
            handleToggle={() => toggleMenu('support')}
            isOpen={menuOpened.support}
            sidebarIsOpen={open}
            text={translate('telemedic.menu.support')}
            icon={<ComputerIcon />}
            dense={dense}
          >
            {
              permissions.check(Section.TERMINALS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.TERMINALS}
                  primaryText={translate('telemedic.terminals.label')}
                  leftIcon={<ComputerIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.ALCOHOL_SMS_REPORT, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.ALCOHOL_SMS_REPORT}
                  primaryText={translate('telemedic.alcoholSmsReport.label')}
                  leftIcon={<SearchIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.AIS_ERP_INTEGRATION, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.AIS_ERP_INTEGRATION}
                  primaryText={translate('telemedic.aisErpIntegration.label')}
                  leftIcon={<ComputerIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            <StyledMenuItemLink
              to={RoutePath.LOGS}
              primaryText={translate('telemedic.logs.label')}
              leftIcon={<ShowChartIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            {
              permissions.check(Section.CHECKUPS, Action.IMPORT) && (
                <StyledMenuItemLink
                  to={RoutePath.CHECKUPS_IMPORT}
                  primaryText={translate('telemedic.checkupsImport.label')}
                  leftIcon={<ArchiveIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.USERS, Action.CREATE) && (
                <StyledMenuItemLink
                  to={RoutePath.USERS_IMPORT}
                  primaryText={translate('telemedic.usersImport.label')}
                  leftIcon={<ArchiveIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.VEHICLES, Action.CREATE) && (
                <StyledMenuItemLink
                  to={RoutePath.VEHICLES_IMPORT}
                  primaryText={translate('telemedic.vehiclesImport.label')}
                  leftIcon={<ArchiveIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.SERVER_MONITORING, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.EXTERNAL_LINK}
                  primaryText={translate('telemedic.menu.serverMonitoring')}
                  leftIcon={<ShowChartIcon />}
                  onClick={openServerMonitoringUrl}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.TERMINAL_MONITORING, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.EXTERNAL_LINK}
                  primaryText={translate('telemedic.menu.terminalMonitoring')}
                  leftIcon={<ShowChartIcon />}
                  onClick={openTerminalMonitoringUrl}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.ACTIVE_USERS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.ACTIVE_USERS}
                  primaryText={translate('telemedic.activeUsers.label')}
                  leftIcon={<SupervisorAccountIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.DISK_SPACE, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.DISK_SPACE}
                  primaryText={translate('telemedic.diskSpace.label')}
                  leftIcon={<ShowChartIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
          </SubMenu>
        )
      }
      {
        (permissions.check(Section.STATISTICS_CHECKUP, Action.READ)
        || permissions.check(Section.STATISTICS_TECH, Action.READ)
        || permissions.check(Section.USAGE_CHECKUP, Action.READ)
        || permissions.check(Section.USAGE_TECH, Action.READ)
        || permissions.check(Section.HEALTH_STATUS_REPORTS, Action.READ)
        || permissions.check(Section.MEDIC_WORKLOAD_REPORTS, Action.READ)
        || permissions.check(Section.MEDIC_EFFICIENCY_REPORTS, Action.READ)
        || permissions.check(Section.RECEIVED_CHECKUPS_REPORTS, Action.READ)
        || permissions.check(Section.MEDIC_ECP_REPORTS, Action.READ)
        || permissions.check(Section.TERMINAL_VERIFICATION_REPORTS, Action.READ))
        && (
          <SubMenu
            handleToggle={() => toggleMenu('analytics')}
            isOpen={menuOpened.analytics}
            sidebarIsOpen={open}
            text={translate('telemedic.menu.analytics')}
            icon={<BarChartIcon />}
            dense={dense}
          >
            {
              (permissions.check(Section.STATISTICS_CHECKUP, Action.READ)
              || permissions.check(Section.USAGE_CHECKUP, Action.READ)
              || permissions.check(Section.HEALTH_STATUS_REPORTS, Action.READ)
              || permissions.check(Section.MEDIC_WORKLOAD_REPORTS, Action.READ)
              || permissions.check(Section.MEDIC_EFFICIENCY_REPORTS, Action.READ)
              || permissions.check(Section.RECEIVED_CHECKUPS_REPORTS, Action.READ))
              && (
                <SubMenu
                  handleToggle={() => toggleMenu('checkupStats')}
                  isOpen={menuOpened.checkupStats}
                  sidebarIsOpen={open}
                  text={translate('telemedic.menu.checkups')}
                  icon={<LocalHospitalIcon />}
                  dense={dense}
                >
                  {
                    permissions.check(Section.STATISTICS_CHECKUP, Action.READ) && (
                      <StyledMenuItemLink
                        to={RoutePath.STATISTICS_CHECKUP}
                        primaryText={translate('telemedic.statistics.label')}
                        leftIcon={<BarChartIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                    )
                  }
                  {
                    permissions.check(Section.USAGE_CHECKUP, Action.READ) && (
                      <StyledMenuItemLink
                        to={RoutePath.USAGE_CHECKUP}
                        primaryText={translate('telemedic.usage.label')}
                        leftIcon={<DataUsageIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                    )
                  }
                  {
                    permissions.check(Section.BILLING_SUM, Action.READ) && (
                      <StyledMenuItemLink
                        to={RoutePath.BILLING_SUM}
                        primaryText={translate('telemedic.billingSum.label')}
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                    )
                  }
                  {
                    permissions.check(Section.HEALTH_STATUS_REPORTS, Action.READ) && (
                      <StyledMenuItemLink
                        to={RoutePath.HEALTH_STATUS}
                        primaryText={translate('telemedic.healthStatus.label')}
                        leftIcon={<AccessibilityIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                    )
                  }
                  {
                    permissions.check(Section.MEDIC_WORKLOAD_REPORTS, Action.READ) && (
                      <StyledMenuItemLink
                        to={RoutePath.MEDIC_WORKLOAD}
                        primaryText={translate('telemedic.medicWorkload.label')}
                        leftIcon={<WorkIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                    )
                  }
                  {
                    permissions.check(Section.MEDIC_EFFICIENCY_REPORTS, Action.READ) && (
                      <StyledMenuItemLink
                        to={RoutePath.MEDIC_EFFICIENCY}
                        primaryText={translate('telemedic.medicEfficiency.label')}
                        leftIcon={<SpeedIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                    )
                  }
                  {
                    permissions.check(Section.RECEIVED_CHECKUPS_REPORTS, Action.READ) && (
                      <StyledMenuItemLink
                        to={RoutePath.RECEIVED_CHECKUPS}
                        primaryText={translate('telemedic.receivedCheckups.label')}
                        leftIcon={<ShowChartIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                    )
                  }
                </SubMenu>
              )
            }
            {
              (permissions.check(Section.STATISTICS_TECH, Action.READ)
              || permissions.check(Section.USAGE_TECH, Action.READ))
              && (
                <SubMenu
                  handleToggle={() => toggleMenu('techInspectionStats')}
                  isOpen={menuOpened.techInspectionStats}
                  sidebarIsOpen={open}
                  text={translate('telemedic.menu.techInspections')}
                  icon={<DirectionsCarIcon />}
                  dense={dense}
                >
                  {
                    permissions.check(Section.STATISTICS_TECH, Action.READ) && (
                      <StyledMenuItemLink
                        to={RoutePath.STATISTICS_TECH}
                        primaryText={translate('telemedic.statistics.label')}
                        leftIcon={<BarChartIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                    )
                  }
                  {
                    permissions.check(Section.USAGE_TECH, Action.READ) && (
                      <StyledMenuItemLink
                        to={RoutePath.USAGE_TECH}
                        primaryText={translate('telemedic.usage.label')}
                        leftIcon={<DataUsageIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                      />
                    )
                  }
                </SubMenu>
              )
            }
            {
              permissions.check(Section.MEDIC_ECP_REPORTS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.CONTROL_MEDIC_ECP}
                  primaryText={translate('telemedic.control.medicEcp.label')}
                  leftIcon={<InsertDriveFileIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.TERMINAL_VERIFICATION_REPORTS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.CONTROL_TERMINAL_VERIFICATION}
                  primaryText={translate('telemedic.control.terminalVerification.label')}
                  leftIcon={<InsertDriveFileIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.SMS_REPORT, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.SMS_REPORT}
                  primaryText={translate('telemedic.control.smsReport.label')}
                  leftIcon={<SmsIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.TERMINAL_STATUS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.CONTROL_TERMINAL_STATUS}
                  primaryText={translate('telemedic.control.terminalStatus.label')}
                  leftIcon={<InsertDriveFileIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
          </SubMenu>
        )
      }
      {
        (permissions.check(Section.VEHICLES, Action.READ)
        || permissions.check(Section.TECH_INSPECTIONS, Action.READ)
        || permissions.check(Section.TECH_INSPECTION_JOURNALS, Action.READ)
        || permissions.check(Section.INSPECTION_POINTS, Action.READ)
        || permissions.check(Section.WAYBILLS, Action.READ)
        || permissions.check(Section.WAYBILLS_MOVEMENT, Action.READ)) && (
          <SubMenu
            handleToggle={() => toggleMenu('waybill')}
            isOpen={menuOpened.waybill}
            sidebarIsOpen={open}
            text={translate('telemedic.menu.waybill')}
            icon={<DirectionsCarIcon />}
            dense={dense}
          >
            {
              permissions.check(Section.TECH_INSPECTIONS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.TECH_INSPECTIONS_LIVE}
                  primaryText={translate('telemedic.techInspectionsLive.label')}
                  leftIcon={<BuildIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.TECH_INSPECTIONS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.TECH_INSPECTIONS}
                  primaryText={translate('telemedic.techInspections.label')}
                  leftIcon={<AccessTimeIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.TECH_INSPECTION_JOURNALS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.TECH_INSPECTION_JOURNALS}
                  primaryText={translate('telemedic.techInspectionJournals.label')}
                  leftIcon={<PrintIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.VEHICLES, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.VEHICLES}
                  primaryText={translate('telemedic.vehicles.label')}
                  leftIcon={<DirectionsCarIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.INSPECTION_POINTS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.INSPECTION_POINTS}
                  primaryText={translate('telemedic.inspectionPoints.label')}
                  leftIcon={<AdjustIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.WAYBILLS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.WAYBILLS}
                  primaryText={translate('telemedic.waybills.label')}
                  leftIcon={<AssignmentTurnedInIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.EWAYBILLS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.EWAYBILLS}
                  primaryText={translate('telemedic.ewaybills.label')}
                  leftIcon={<AssignmentTurnedInIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.WAYBILLS_MOVEMENT, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.WAYBILLS_MOVEMENT}
                  primaryText={translate('telemedic.waybillsMovement.label')}
                  leftIcon={<AssignmentTurnedInIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
          </SubMenu>
        )
      }
      {
        (permissions.check(Section.TEST_QUESTION_CATEGORIES, Action.READ)
        || permissions.check(Section.TEST_RESULTS, Action.READ)) && (
          <SubMenu
            handleToggle={() => toggleMenu('test')}
            isOpen={menuOpened.test}
            sidebarIsOpen={open}
            text={translate('telemedic.menu.test')}
            icon={<QuestionAnswer />}
            dense={dense}
          >
            {
              permissions.check(Section.TEST_RESULTS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.TEST_RESULTS_LIVE}
                  primaryText={translate('telemedic.testResultsLive.label')}
                  leftIcon={<QuestionAnswer />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.TEST_RESULTS, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.TEST_RESULTS}
                  primaryText={translate('telemedic.testResults.label')}
                  leftIcon={<QuestionAnswer />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
            {
              permissions.check(Section.TEST_QUESTION_CATEGORIES, Action.READ) && (
                <StyledMenuItemLink
                  to={RoutePath.TEST_QUESTION_CATEGORIES}
                  primaryText={translate('telemedic.testQuestionCategory.label')}
                  leftIcon={<QuestionAnswer />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )
            }
          </SubMenu>
        )
      }
      <StyledMenuItemLink
        to={RoutePath.ACTION}
        primaryText={translate('telemedic.actions.logout')}
        leftIcon={<ExitIcon />}
        onClick={logout}
        sidebarIsOpen={open}
        dense={dense}
      />
    </div>
  );
};

export default Menu;
